.font_1 {
    font-size: 0.32rem;
    line-height: 0.28rem;
    color: #ffffff;
    padding-left: 0.2rem;
  }
  .section_21 {
    padding: 0.48rem 0 0.43rem;
    background: radial-gradient(#121118, #313545);  }
  .space-y-14 > *:not(:first-child) {
    margin-top: 0.28rem;
  }
  .image_41 {
    width: 2.2rem;
  }
  .text_13 {
    color: #ffffff;
    font-size: 0.28rem;
    font-family: 'Arial';
    line-height: 0.32rem;
  }
  .group_22 {
    margin-top: 0.57rem;
    padding: 0 1rem;
  }
  .group_23 {
    padding: 0 0.2rem;
  }
  .text_14 {
    margin-right: 0.66rem;
  }
  .group_24 {
    margin-top: 0.24rem;
  }
  .image_42 {
    width: 0.16rem;
    height: 0.16rem;
  }
  .font_8 {
    font-size: 0.28rem;
    line-height: 0.26rem;
    color: #ffffff;
  }
  .group_25 {
    margin-right: 0.86rem;
  }
  .image_43 {
    width: 0.08rem;
    height: 0.15rem;
  }
  .group_26 {
    margin-top: 0.37rem;
  }
  .group_27 {
    margin-right: 0.54rem;
  }
  .group_28 {
    margin-top: 0.37rem;
  }
  .group_29 {
    margin-right: 1.21rem;
  }
  .group_30 {
    margin-top: 0.37rem;
  }
  .group_31 {
    margin-right: 1.13rem;
  }
  .group_32 {
    margin-top: 0.37rem;
  }
  .space-x-4 > *:not(:first-child) {
    margin-left: 0.04rem;
  }

  .space-x-4{
    margin-top:0.39rem;
  }
  .grid {
    margin-top: 0.62rem;
    padding-bottom: 1.04rem;
    height: 2.08rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item {
    padding: 0.18rem 0;
  }
  .space-y-24 > *:not(:first-child) {
    margin-top: 0.24rem;
  }
  .image_44 {
    margin-left: 1.04rem;
  }
  .image_45 {
    margin-left: 1.06rem;
  }
  .grid-item_2 {
    padding: 0.18rem 0 0.66rem;
    /* width: 3.75rem; */
  }
  .space-x-8 > *:not(:first-child) {
    margin-left: 0.08rem;
  }
  .image_46 {
    margin-left: 1.04rem;
  }
  .image_47 {
    margin-left: 1.06rem;
  }
  .group_33 {
    margin-top: 0.62rem;
  }
  .space-x-32 > *:not(:first-child) {
    margin-left: 0.32rem;
  }
  .image_48 {
    width: 0.35rem;
    height: 0.35rem;
  }
  .image_49 {
    border-radius: 0.095rem;
    width: 0.29rem;
    height: 0.29rem;
  }
  .line-4{
   line-height: 0.4rem;
  }