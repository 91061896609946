.image {
  border-radius: 8px;
  width: 170px;
  /* height: 40px; */
}
.section_10 {
  /* margin-top: 120px;
  padding: 75px 385px 82px; */
  margin-top: 7.5rem;
  /* padding: 4.69rem 24.06rem 5.13rem; */
  align-items: center;
  background: radial-gradient(#121118, #313545);
}
.group_13 {
  padding-left: 8px;
  padding-right: 5px;
}
.text_14 {
  color: #ffffff;
  font-size: 20px;
  font-family: 'Fontquan-XinYilogoTi';
  line-height: 24px;
}
.font_8 {
  font-size: 20px;
  font-family: 'Roboto';
  line-height: 24px;
  font-weight: 700;
  color: #ffffff;
}
.group_14 {
  margin-right: 7px;
  margin-top: 5px;
}
.text_15 {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Arial';
  line-height: 32px;
  text-align: center;
}
.group_15 {
  margin-right: -128px;
}
.space-y-26 > *:not(:first-child) {
  margin-top: 26px;
}
.group_16 {
  padding: 14px 0 11px;
}
.image_17 {
  width: 16px;
  height: 16px;
}
.image_18 {
  margin-left: 110px;
}
.pos_5 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.equal-division-item_2 {
  padding: 10px 128px;
  flex: 1 1 547px;
}
.font_9 {
  font-size: 16px;
  font-family: 'Roboto';
  line-height: 21px;
  color: #ffffff;
  align-items: center;
}
.image_19 {
  width: 16px;
  height: 16px;
}
.equal-division-item_3 {
  padding: 10px 60px;
  flex: 1 1 547px;
}
.group_17 {
  margin-right: 68px;
}
.group_18 {
  padding: 0 110px;
}
.space-x-92 > *:not(:first-child) {
  margin-left: 92px;
}
.group_19 {
  margin-right: 77px;
}
.space-x-114 > *:not(:first-child) {
  margin-left: 114px;
}
.group_20 {
  margin-top: 29px;
}
.space-x-113 > *:not(:first-child) {
  margin-left: 113px;
}
.space-x-26 > *:not(:first-child) {
  margin-left: 26px;
}
.image_20 {
  width: 24px;
  height: 24px;
}
.image_21 {
  border-radius: 8px;
  width: 24px;
  height: 24px;
}
.group_21 {
  margin-right: 318px;
}
.group_22 {
  margin-top: 36px;
  padding: 0 287px;
}
.space-x-4 > *:not(:first-child) {
  margin-left: 4px;
}
.group_23 {
  margin-right: 22px;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 8px;
}

.title{
  font-size: 20px;
  font-family: Roboto, Roboto-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 24px;
}