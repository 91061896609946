.section {
  /* padding: 16px 212px 16px 340px; */
  padding: 16px 2rem 16px 2rem;
  background-color: #f7f7f7;
  justify-content: center;
}
.space-x-117{
  max-width: 1240px;
}
.space-x-117 > *:not(:first-child) {
  /* margin-left: 117px; */
}
.image {
  border-radius: 8px;
  width: 170px;
}
.text {
  color: #000000;
  font-size: 16px;
  font-family: 'Fontquan-XinYilogoTi';
  line-height: 19px;
}
.space-x-80 > *:not(:first-child) {
  margin-left: 80px;
}
.space-x-6 > *:not(:first-child) {
  margin-left: 6px;
}
.font_1 {
  font-size: 16px;
  font-family: 'Roboto';
  line-height: 21px;
  color: #000000;
}
.image_4 {
  width: 7px;
  height: 4px;
}
.space-x-16 > *:not(:first-child) {
  margin-left: 16px;
}
.image_2 {
  border-radius: 4px;
  width: 73px;
  height: 36px;
}
.image_3 {
  border-radius: 4px;
  width: 112px;
  height: 38px;
}
.space-x-4 > *:not(:first-child) {
  margin-left: 4px;
}