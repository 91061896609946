.header {
  padding: 0.24rem 0.32rem;
  height: 1rem;
  border-bottom: solid 0.01rem #e7e7e7;
  position: fixed;
  display: flex;
  flex: 1;
  width: 100%;
  background: #fff;
  z-index: 10;
}
.image_2 {
  width: 0.4rem;
  height: 0.36rem;
}
.pos {
  position: absolute;
  left: 0.32rem;
  top: 50%;
  transform: translateY(-50%);
}
.space-x-6 > *:not(:first-child) {
  margin-left: 0.06rem;
}
.image {
  width: 1.2rem;
}
.font_1 {
  font-size: 0.32rem;
  line-height: 0.23rem;
  color: #ffffff;
}
.text {
  color: #000000;
  font-size: 0.32rem;
}

 .line {
  width: 0.4rem;
  height: 0.059rem;
  background: linear-gradient(135deg,#36d0ac,#60f770);
  display: block;
  margin: 0.09rem auto;
  transition: all 0.3s ease-in-out;
}

.styleOpacity{
  opacity: 0;
}
.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(0.19rem) rotate(45deg);
  -ms-transform: translateY(0.19rem) rotate(45deg);
  -o-transform: translateY(0.19rem) rotate(45deg);
  transform: translateY(0.19rem) rotate(45deg);
}
.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-0.11rem) rotate(-45deg);
  -ms-transform: translateY(-0.11rem) rotate(-45deg);
  -o-transform: translateY(-0.11rem) rotate(-45deg);
  transform: translateY(-0.11rem) rotate(-45deg);
}