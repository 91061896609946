@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-animation1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fade-in-animation2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in-animation 1s ease;
}
.fade-in1 {
  animation: fade-in-animation1 1s ease;
}
.fade-in2 {
  animation: fade-in-animation2 1s ease;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.header {
  padding: 0.24rem 0.32rem;
  height: 1rem;
  border-bottom: solid 0.01rem #e7e7e7;
}
.image_2 {
  width: 0.4rem;
  height: 0.36rem;
}
.pos {
  position: absolute;
  left: 0.32rem;
  top: 50%;
  transform: translateY(-50%);
}
.space-x-6 > *:not(:first-child) {
  margin-left: 0.06rem;
}
.image {
  border-radius: 0.095rem;
  width: 0.48rem;
  height: 0.49rem;
}
.font_1 {
  font-size: 0.2rem;
  
  line-height: 0.23rem;
  color: #ffffff;
}
.text {
  color: #000000;
  font-size: 0.2rem;
}
.group {
  overflow-y: auto;
}
.section {
  /* padding: 0.78rem 0.6rem 1.06rem; */
  /* background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566446176794764.png'); */
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 8rem;
}
.space-y-29 > *:not(:first-child) {
  margin-top: 0.29rem;
}
.space-y-7 > *:not(:first-child) {
  margin-top: 0.07rem;
}
.text-wrapper {
  margin: 0 0.06rem;
  overflow: hidden;
  height: 0.58rem;
}
.text_2 {
  margin-top: -0.1rem;
  color: #ffffff;
  font-size: 0.44rem;
  
  line-height: 0.76rem;
}
.font_2 {
  font-size: 0.32rem;
  
}
.text_3 {
  color: #ffffff;
  line-height: 0.28rem;
}
.group_2 {
  padding: 0 0.9rem;
  height: 4.93rem;
}
.group_3 {
  padding: 0.58rem 0 0.06rem;
}
.section_2 {
  margin: 0 0.3rem;
  padding: 0.76rem 0.76rem 1.42rem 0.96rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/47d91ecb23074b9f56e2c035089e43e5.png');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_5 {
  padding-bottom: 0.08rem;
}
.image_6 {
  width: 0.72rem;
  height: 0.51rem;
}
.image_14 {
  border-radius: 50%;
  width: 0.04rem;
  height: 0.04rem;
}
.image_15 {
  margin-top: 0.22rem;
}
.image_22 {
  margin-top: 0.82rem;
}
.image_13 {
  width: 0.18rem;
  height: 0.98rem;
}
.pos_20 {
  position: absolute;
  left: 0.11rem;
  bottom: 0;
}
.section_5 {
  margin-top: 1.28rem;
  background-color: #95e4ca;
  width: 0.05rem;
  height: 0.2rem;
}
.group_4 {
  padding-bottom: 0.38rem;
  filter: drop-shadow(0px 0.09rem 0.025rem #00e388ff);
  width: 0.91rem;
}
.pos_2 {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.image_3 {
  width: 0.91rem;
  height: 0.91rem;
}
.image_4 {
  width: 0.46rem;
  height: 0.85rem;
}
.pos_3 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.pos_4 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.pos_5 {
  position: absolute;
  left: 0;
  top: 0.85rem;
}
.pos_8 {
  position: absolute;
  left: 0;
  top: 1.3rem;
}
.pos_9 {
  position: absolute;
  left: 0.45rem;
  top: 1.3rem;
}
.pos_27 {
  position: absolute;
  left: 0;
  bottom: 0.94rem;
}
.pos_29 {
  position: absolute;
  left: 0;
  bottom: 0.55rem;
}
.pos_30 {
  position: absolute;
  left: 0.45rem;
  bottom: 0.55rem;
}
.pos_6 {
  position: absolute;
  right: 0;
  top: 0.85rem;
}
.pos_11 {
  position: absolute;
  right: 0.45rem;
  top: 1.3rem;
}
.pos_12 {
  position: absolute;
  right: 0;
  top: 1.3rem;
}
.pos_28 {
  position: absolute;
  right: 0;
  bottom: 0.94rem;
}
.pos_32 {
  position: absolute;
  right: 0.45rem;
  bottom: 0.55rem;
}
.pos_33 {
  position: absolute;
  right: 0;
  bottom: 0.55rem;
}
.pos_35 {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.image_24 {
  width: 0.7rem;
  height: 0.43rem;
}
.pos_34 {
  position: absolute;
  right: 1.53rem;
  bottom: 0.6rem;
}
.image_23 {
  width: 0.84rem;
  height: 0.51rem;
}
.pos_31 {
  position: absolute;
  left: 1rem;
  bottom: 0.9rem;
}
.image_5 {
  width: 0.57rem;
  height: 0.66rem;
}
.pos_7 {
  position: absolute;
  right: 1.54rem;
  top: 1.1rem;
}
.image_20 {
  width: 2.25rem;
  height: 1.37rem;
}
.pos_25 {
  position: absolute;
  left: 50%;
  bottom: 0.84rem;
  transform: translateX(-50%);
}
.image_9 {
  width: 0.24rem;
  height: 1.19rem;
}
.pos_15 {
  position: absolute;
  left: 1.13rem;
  bottom: 1.56rem;
}
.image_7 {
  width: 0.73rem;
  height: 1.5rem;
}
.pos_13 {
  position: absolute;
  left: 1.38rem;
  top: 50%;
  transform: translateY(-50%);
}
.image_12 {
  width: 0.12rem;
  height: 1.13rem;
}
.pos_18 {
  position: absolute;
  left: 1.65rem;
  bottom: 1.42rem;
}
.image_10 {
  width: 0.21rem;
  height: 1.16rem;
}
.pos_16 {
  position: absolute;
  left: 1.45rem;
  bottom: 1.42rem;
}
.image_16 {
  width: 0.22rem;
  height: 1.17rem;
}
.pos_21 {
  position: absolute;
  left: 1.69rem;
  bottom: 1.27rem;
}
.section_4 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566490734194229.png');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 1.51rem;
}
.pos_14 {
  position: absolute;
  left: 1.9rem;
  right: 1.89rem;
  top: 1.75rem;
}
.image-wrapper {
  padding-top: 0.44rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566491069882984.png');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 0.69rem;
  height: 1.52rem;
}
.pos_17 {
  position: absolute;
  right: 1.5rem;
  top: 1.97rem;
}
.image_21 {
  margin-left: 0.06rem;
  width: 0.19rem;
  height: 1.13rem;
}
.section_3 {
  padding: 0.32rem 0.2rem 0.12rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566492740470500.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 2.25rem;
}
.pos_10 {
  position: absolute;
  left: 50%;
  top: 1.28rem;
  transform: translateX(-50%);
}
.image_8 {
  width: 0.59rem;
  height: 0.42rem;
}
.image_11 {
  margin-top: -0.06rem;
  width: 0.6rem;
  height: 0.56rem;
}
.image_18 {
  width: 0.92rem;
  height: 1.51rem;
}
.pos_23 {
  position: absolute;
  right: 1.13rem;
  bottom: 0.86rem;
}
.pos_26 {
  position: absolute;
  left: 50%;
  bottom: 1.05rem;
  transform: translateX(-50%);
}
.pos_19 {
  position: absolute;
  left: 1.65rem;
  bottom: 1.42rem;
}
.image_17 {
  width: 0.14rem;
  height: 0.17rem;
}
.pos_22 {
  position: absolute;
  right: 1.96rem;
  top: 2.12rem;
}
.image_19 {
  width: 0.1rem;
  height: 0.1rem;
}
.pos_24 {
  position: absolute;
  right: 2.12rem;
  top: 50%;
  transform: translateY(-50%);
}
.group_6 {
  margin-top: -0.46rem;
}
.group_7 {
  padding-left: 0.4rem;
  padding-top: 0.37rem;
}
.group_8 {
  /* padding: 0 0.92rem; */
}
.space-y-15 > *:not(:first-child) {
  margin-top: 0.15rem;
}
.font_3 {
  font-size: 0.5rem;
  line-height: 0.58rem;
  color: #000000cc;
}
.works_font {
  font-size: 0.5rem;
  line-height: 0.58rem;
  color: #000000cc;
}
.section_6 {
  background-image: linear-gradient(91.4deg, #36d0ac 0%, #36d0ac -20.71%, #60f770 120.71%, #60f770 100%);
  border-radius: 0.02rem;
  width: 1.6rem;
  height: 0.04rem;
}
.view {
  /* margin-left: 1.49rem; */
}
.group_9 {
  margin-top: 0.4rem;
}
.space-y-38 > *:not(:first-child) {
  margin-top: 0.38rem;
}
.section_7 {
  margin-right: 0.4rem;
  padding: 0.2rem 0.28rem 0.32rem;
  background-color: #fbfbfb;
  border-radius: 0.08rem;
  border: solid 0.015rem #f1f1f1;
}
.space-x-53 > *:not(:first-child) {
  margin-left: 0.53rem;
}
.image_25 {
  width: 0.8rem;
  height: 0.8rem;
}
.group_10 {
  margin-right: 0.07rem;
}
.space-y-17 > *:not(:first-child) {
  margin-top: 0.17rem;
}
.font_4 {
  font-size: 0.4rem;
  line-height: 0.47rem;
  color: #000000cc;
}
.font_5 {
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #00000099;
}
.text_4 {
  line-height: 0.48rem;
  text-align: left;
}
.group_11 {
  margin-top: 0.8rem;
}
.space-y-80 > *:not(:first-child) {
  margin-top: 0.8rem;
}
.section_8 {
  margin-right: 0.4rem;
  background-position: 0% 0%;
  background-size: 6.7rem 4.85rem;
  background-repeat: no-repeat;
}
.section_9 {
  padding: 0.24rem 0 0.26rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/0efbce994e70a8ff4b63685b4d42a30f.png');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 4.57rem;
}
.group_12 {
  padding: 0 0.2rem;
  height: 0.99rem;
}
.image_27 {
  margin-top: 0.34rem;
  width: 0.65rem;
  height: 0.65rem;
}
.image_26 {
  margin-right: 0.6rem;
  margin-top: -1rem;
  border-radius: 50%;
  width: 0.45rem;
  height: 0.45rem;
}
.group_13 {
  margin-right: 0.08rem;
  margin-top: 0.58rem;
}
.section_11 {
  margin-right: 0.96rem;
  padding-bottom: 0.11rem;
  background-image: linear-gradient(203.1deg, #36d0ac 0%, #36d0ac -6.46%, #60f770 102.25%, #60f770 100%);
  border-radius: 50%;
  width: 0.77rem;
  height: 0.77rem;
}
.text_5 {
  margin-top: 0.45rem;
  color: #ffffff;
  font-size: 0.15rem;
  font-family: 'PingFang SC';
  font-weight: 600;
  line-height: 0.21rem;
  letter-spacing: -0.01rem;
}
.image_28 {
  margin-top: -0.66rem;
  width: 0.51rem;
  height: 0.5rem;
}
.group_14 {
  padding-top: 0.22rem;
}
.space-y-16 > *:not(:first-child) {
  margin-top: 0.16rem;
}
.image_29 {
  width: 0.45rem;
  height: 0.45rem;
}
.image_30 {
  border-radius: 50%;
  width: 0.73rem;
  height: 0.73rem;
}
.section_12 {
  background-color: #ffea9c;
  border-radius: 50%;
  height: 0.72rem;
}
.pos_37 {
  position: absolute;
  left: 0.81rem;
  right: 3.04rem;
  top: 3.69rem;
}
.section_10 {
  background-color: #bcff9b;
  border-radius: 50%;
  width: 0.47rem;
  height: 0.47rem;
}
.pos_36 {
  position: absolute;
  right: 1.05rem;
  top: 1.57rem;
}
.section_13 {
  margin-right: 0.4rem;
  padding: 0.39rem 0.16rem 0.58rem;
  background-image: linear-gradient(295.4deg, #36d0ac 0%, #36d0ac -20.52%, #4de68a 242.84%, #4de68a 100%);
  border-radius: 0px 0px 0.08rem 0.08rem;
}
.space-y-27 > *:not(:first-child) {
  margin-top: 0.27rem;
}
.text_6 {
  color: #ffffff;
}
.font_6 {
  font-size: 0.32rem;
  line-height: 0.4rem;
  color: #ffffffcc;
}
.text_7 {
  line-height: 0.4rem;
}
.section_14 {
  margin-right: 0.4rem;
  padding: 0.66rem 0.4rem 0;
  border-radius: 0.08rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566505918100156.png');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_15 {
  padding: 3.83rem 0 0.04rem;
}
.section_20 {
  background-color: #d6d6d6;
  width: 1.04rem;
  height: 0.52rem;
}
.image_38 {
  width: 1.11rem;
  height: 0.55rem;
}
.pos_39 {
  position: absolute;
  right: 2.34rem;
  bottom: 0.04rem;
}
.section_15 {
  padding: 0.02rem 0 0.59rem;
  background-color: #ffffff;
  border-radius: 0.15rem 0.15rem 0.15rem 0.15rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566511622284499.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.pos_38 {
  position: absolute;
  left: 0.03rem;
  right: 0;
  top: 0;
}
.section_16 {
  margin-right: 0.03rem;
  padding-top: 0.34rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566511682327717.png');
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_16 {
  padding: 0 0.54rem;
}
.font_7 {
  font-size: 0.2rem;
  font-family: 'PingFang SC';
  line-height: 0.28rem;
  font-weight: 500;
  color: #ffffff;
}
.text_8 {
  margin-top: 0.15rem;
}
.image_31 {
  width: 0.49rem;
  height: 0.84rem;
}
.image_32 {
  margin-left: 0.4rem;
}
.image_33 {
  margin-left: 0.28rem;
}
.image_34 {
  margin-left: 0.34rem;
}
.group_17 {
  margin-top: 0.07rem;
}
.section_17 {
  margin-left: 2rem;
  background-color: #ffffff;
  border-radius: 0.07rem;
  width: 1.83rem;
  height: 0.98rem;
  border: solid 0.01rem #979797;
}
.text_9 {
  color: #000000;
}
.image_35 {
  width: 1.57rem;
  height: 0.67rem;
}
.section_18 {
  margin-left: -3.82rem;
  background-image: repeating-linear-gradient(
    90deg,
    #979797,
    #979797 1.9966527737489976%,
    transparent 1.9966527737489976%,
    transparent 3.9933055474979953%
  );
  width: 2rem;
  height: 0.01rem;
}
.section_19 {
  margin-left: 1.82rem;
  margin-top: 0.56rem;
  background-image: repeating-linear-gradient(
    90deg,
    #979797,
    #979797 2.058360999475799%,
    transparent 2.058360999475799%,
    transparent 4.116721998951598%
  );
  width: 1.95rem;
  height: 0.01rem;
}
.group_18 {
  padding: 0.1rem 0.54rem 0.14rem;
}
.text_10 {
  margin-top: 0.36rem;
}
.group_19 {
  width: 2.61rem;
}
.space-y-8 > *:not(:first-child) {
  margin-top: 0.08rem;
}
.image_36 {
  margin-left: 0.2rem;
  width: 0.14rem;
  height: 0.23rem;
}
.image_37 {
  width: 0.54rem;
  height: 0.53rem;
}
.image_39 {
  width: 3rem;
  height: 0.06rem;
}
.pos_40 {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.group_20 {
  padding: 0.58rem 0;
}
.space-y-23 > *:not(:first-child) {
  margin-top: 0.23rem;
}
.text_11 {
  color: #39d2a9;
}
.text_12 {
  color: #949494;
  line-height: 0.4rem;
  text-align: justify;
  word-break: break-all;
}
.group_21 {
  overflow-x: hidden;
}
.view_2 {
  margin-top: 0.77rem;
}
.space-y-40 > *:not(:first-child) {
  margin-top: 0.4rem;
}
.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}
.panel-scroll{
  overflow-x: scroll !important;
}
.image_40 {
  width: 5.2rem;
  height: 4.6rem;
}
.section_21 {
  padding: 0.48rem 0 0.43rem;
  background-image: radial-gradient(ellipse 240.47% -1.78% at 13.94% -54.99%, #121118 0%, #313545 100%);
}
.space-y-14 > *:not(:first-child) {
  margin-top: 0.14rem;
}
.image_41 {
  border-radius: 0.08rem;
  width: 0.4rem;
  height: 0.4rem;
}
.text_13 {
  color: #ffffff;
  font-size: 0.18rem;
  font-family: 'Arial';
  line-height: 0.32rem;
}
.group_22 {
  margin-top: 0.57rem;
  padding: 0 1rem;
}
.group_23 {
  padding: 0 0.2rem;
}
.text_14 {
  margin-right: 0.66rem;
}
.group_24 {
  margin-top: 0.24rem;
}
.image_42 {
  width: 0.16rem;
  height: 0.16rem;
}
.font_8 {
  font-size: 0.16rem;
  
  line-height: 0.18rem;
  color: #ffffff;
}
.group_25 {
  margin-right: 0.86rem;
}
.image_43 {
  width: 0.08rem;
  height: 0.15rem;
}
.group_26 {
  margin-top: 0.37rem;
}
.group_27 {
  margin-right: 0.54rem;
}
.group_28 {
  margin-top: 0.37rem;
}
.group_29 {
  margin-right: 1.21rem;
}
.group_30 {
  margin-top: 0.37rem;
}
.group_31 {
  margin-right: 1.13rem;
}
.group_32 {
  margin-top: 0.37rem;
}
.space-x-4 > *:not(:first-child) {
  margin-left: 0.04rem;
}
.grid {
  margin-top: 0.62rem;
  padding-bottom: 1.04rem;
  height: 2.08rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.grid-item {
  padding: 0.18rem 0;
}
.space-y-24 > *:not(:first-child) {
  margin-top: 0.24rem;
}
.image_44 {
  margin-left: 1.04rem;
}
.image_45 {
  margin-left: 1.06rem;
}
.grid-item_2 {
  padding: 0.18rem 0 0.66rem;
  width: 3.75rem;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}
.image_46 {
  margin-left: 1.04rem;
}
.image_47 {
  margin-left: 1.06rem;
}
.group_33 {
  margin-top: 0.62rem;
}
.space-x-32 > *:not(:first-child) {
  margin-left: 0.32rem;
}
.image_48 {
  width: 0.29rem;
  height: 0.29rem;
}
.image_49 {
  border-radius: 0.095rem;
  width: 0.29rem;
  height: 0.29rem;
}